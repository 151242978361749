// RUPTURE
rupture.anti-overlap = -1px
rupture.mobile-cutoff = $mobile-cutoff
rupture.desktop-cutoff = $desktop-cutoff
rupture.hd-cutoff = $hd-cutoff
rupture.scale = 0 $mobile-cutoff 768px 992px $desktop-cutoff $hd-cutoff

// LOST
@lost gutter 40px
@lost flexbox flex
