.footer
  display flex
  font-text-regular()
  justify-content space-between
  padding 140px $padding-desktop 40px $padding-desktop
  typography(14,20)
  text-transform uppercase
  letter-spacing .02em
  &__item
    &:nth-child(1n+2)
      margin-left 43px
    span
      +mobile()
        display block
  &__copyright
    text-align right
