.member
  +mobile()
    lost-flex-container row

  &__image
    +mobile()
      align-self center
      lost-column 1/2
      margin-bottom 20px
    +above(rupture.mobile-cutoff)
      width 100%
      margin-bottom 30px

  &__name
    font-text-semibold()
    letter-spacing .01em
    margin-bottom 23px
    +mobile()
      typography(30,33)
    +above(rupture.mobile-cutoff)
      typography(18,22)
    +mobile()
      lost-column 1/2

  &__birthdate
    display block
    +mobile()
      font-text-light()
      typography(18,25)

  &__text
    font-text-light()
    hyphens auto
    letter-spacing .01em
    +below(rupture.desktop-cutoff)
      typography(18,25)
    +desktop()
      typography(14,22)
    +mobile()
      lost-column 1/1
