.project
  lost-flex-container row
  relative 0

  &__sidebar
    +above(rupture.mobile-cutoff)
      absolute left top
    +tablet()
      lost-column 3/12
    +desktop()
      lost-column 2/12
      lost-offset 1/12

  &__projectImage
    height auto
    margin-bottom 64px
    width 100%
    +above(rupture.mobile-cutoff)
      display none

  &__aspect
    display flex
    flex-direction column
    & + &
      margin-top 22px

  &__sidebar-headline
    text-transform uppercase
    +mobile()
      font-text-light()
      typography(18,23)
      letter-spacing .03em
    +above(rupture.mobile-cutoff)
      letter-spacing .02em
      typography(14,22)

  &__sidebar-text
    font-text-bold()
    +mobile()
      letter-spacing .01em
      typography(18,23)
    +above(rupture.mobile-cutoff)
      typography(14,22)

  &__content
    +mobile()
      margin-top 60px

  &__footer
    margin-top 55px
    width 100%
    &:after
      background-color $color__text
      content ''
      display block
      +mobile()
        height 4px
        margin-top 14px
        width 100%
      +above(rupture.mobile-cutoff)
        height 6px
        margin-top 22px
        width 112px

    +above(rupture.mobile-cutoff)
      lost-offset 3/12
      lost-column 7/12

  &__navigation
    display flex
    justify-content flex-end
  &__navigationList
    display inline-flex
    flex-direction column
    justify-content space-between
    padding 0 24px
    +mobile()
      margin 20px 0 40px
    +above(rupture.mobile-cutoff)
      relative top -32px
  &__navigationItem
    &:last-child
      align-self flex-end
      +mobile()
        margin-top 12px
      +above(rupture.mobile-cutoff)
        margin-top 6px
  &__navigationLink
    align-items center
    display flex
    +above(rupture.mobile-cutoff)
      white-space nowrap
  &__navigationArrow
    width 16px
    &--prev
      transform rotate(180deg)
      margin-right 14px
    &--next
      margin-left 14px
