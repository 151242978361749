body
  font-text-regular()
  color $color__text
  -moz-osx-font-smoothing grayscale
  -webkit-font-smoothing antialiased
  text-rendering optimizeLegibility


i
em
  font-style italic

b
strong
  font-text-bold()

a
  color inherit
  text-decoration none

header1()
  font-text-bold()
  letter-spacing .01em
  responsive-typography(45,50,120,144)
  +mobile()
    margin-bottom 12px
  +above(rupture.mobile-cutoff)
    margin-bottom 36px

header2()
  responsive-typography(26,30,33,35)

header3()
  responsive-typography(20,28,24,35)

header4()
  responsive-typography(20,28,20,30)


.default__content
  h1
    header2()
  h2
    header3()
  h3
    header4()

//h1()
//  color $color__brand--0
//  font-headline-bold()
//  margin-bottom .6em
//  header1()
//
//h2()
//  font-headline-bold()
//  header2()
//  color $color__brand--0
//  margin-bottom .75em
//
//h2
//.h2
//  h2()
//
//h3()
//  font-headline-bold()
//  header3()
//  color $color__brand--0
//h3
//.h3
//  h3()
//
//h4()
//  font-text-bold()
//  header4()
//  margin-bottom .5em
//h4
//  h4()

subcopy()
  responsive-typography(20,30,30,45)
.subcopy
  subcopy()

textsize-small()
  responsive-typography(16,22,18,24)

textsize-large()
  responsive-typography(18,25,33,43)

textsize-regular()
  responsive-typography(18,25,18,25)

.text--highlight
  background-image linear-gradient($color__turquoise, $color__turquoise)
  background-position 0 80%
  background-repeat no-repeat
  background-size 100% 6px

richtext()
  a:not([class*=a-button])
    text-decoration underline
  p
  ul
  ol
    textsize-regular()
    + p
    + ul
    + ol
      margin-top 1.25em
    + h2
    + h3
      margin-top 1.5em
  h2
  h3
    + h2
    + h3
      margin-top 1em
  li + li
      margin-top .5em
  ul
    li
      padding-left 1.25em
  ol
    margin-left 1.25em
    list-style-type numeric
  ul li
    relative 0
    &:before
      content ''
      display block
      width .5em
      height .5em
      absolute left top .5em
      background-color $color__text


.headline
  &__xl
    h1()
  &__blue
  &__m
    background $color__brand--0
    color #fff
    display inline-block
    font-text-bold()
    padding 14px 12px 12px 12px
    text-transform uppercase
    typography(22,22,33,33)
    margin-bottom 1em


/*
main
.main
  header
    width 100%
    > h1
      contentPadding()
      header1()
      max-width $content-width
      margin-left auto
      margin-right auto
      elementSpacing--top()
      width 100%
      text-align center
      + h2
        margin-bottom 1em
      +above($mobile-cutoff)
        margin-bottom 1.25em
      +mobile()
        margin-bottom 20px
    > h2
      contentPadding()
      header2()
      hyphens auto
      text-align center
      width 100%

.text-center
  text-align center
.text-right
  text-align right
.text-justify
  text-align justify


*/
