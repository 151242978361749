.default

  lost-flex-container row

  &__content
    richtext()
    +mobile()
      lost-column 1/1
    +tablet()
      lost-offset 2/12
      lost-column 8/12
    +desktop()
      lost-offset 3/12
      lost-column 8/12
