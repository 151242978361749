/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
// Settings
// ==================================================
$hamburger-padding-x = 0
$hamburger-padding-y = 0
$hamburger-layer-width--mobile = 30px
$hamburger-layer-height--mobile = 2px
$hamburger-layer-spacing--mobile = 10px
$hamburger-layer-width--tablet = 50px
$hamburger-layer-height--tablet = 5px
$hamburger-layer-spacing--tablet = 10px
$hamburger-layer-width--desktop = 30px
$hamburger-layer-height--desktop = 3px
$hamburger-layer-spacing--desktop = 6px
$hamburger-layer-color = black
$hamburger-layer-border-radius = 2px
$hamburger-hover-opacity = 1
$hamburger-active-layer-color = $hamburger-layer-color
$hamburger-active-hover-opacity = $hamburger-hover-opacity
// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter = false
$hamburger-hover-filter = opacity(50%)
$hamburger-active-hover-filter = $hamburger-hover-filter
// Types (Remove or comment out what you don’t need)
// ==================================================

// Base Hamburger (We need this)

// ==================================================
// Cooking up additional types:
//
// The Sass for each hamburger type should be nested
// inside an @if directive to check whether or not
// it exists in $hamburger-types so only the CSS for
// included types are generated.
//
// e.g. hamburgers/types/_new-type.scss
//
// @if index($hamburger-types, new-type) {
//   .hamburger--new-type {
//     ...
//   }
// }
// Hamburger
// ==================================================
.hamburger
  +above(rupture.mobile-cutoff)
    display none
  padding $hamburger-padding-y $hamburger-padding-x
  display inline-block
  cursor pointer
  transition-property opacity, filter
  transition-duration 0.15s
  transition-timing-function linear
  // Normalize (<button>)
  font inherit
  color inherit
  text-transform none
  background-color transparent
  border 0
  margin 0
  overflow visible
  &:hover
    if $hamburger-hover-use-filter == true
      filter $hamburger-hover-filter
    else
      opacity $hamburger-hover-opacity
  .-open-navigation &
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after
      background-color $hamburger-active-layer-color
.hamburger-box
  display inline-block
  position relative
  width $hamburger-layer-width--mobile
  height ($hamburger-layer-height--mobile * 3 + $hamburger-layer-spacing--mobile * 2)

.hamburger-inner
  display block
  top 50%
  margin-top ($hamburger-layer-height--mobile / -2)
  &,
  &::before,
  &::after
    background-color $hamburger-layer-color
    border-radius $hamburger-layer-border-radius
    position absolute
    transition-property transform
    transition-duration 0.15s
    transition-timing-function ease
    width $hamburger-layer-width--mobile
    height $hamburger-layer-height--mobile
  &::before,
  &::after
    content ""
    display block
  &::before
    top (($hamburger-layer-spacing--mobile + $hamburger-layer-height--mobile) * -1)
  &::after
    bottom (($hamburger-layer-spacing--mobile + $hamburger-layer-height--mobile) * -1)
/*
* Spin
*/
.hamburger--spin
  .hamburger-inner
    transition-duration 0.22s
    transition-timing-function cubic-bezier(0.55, 0.055, 0.675, 0.19)
    &::before
      transition top 0.1s 0.25s ease-in, opacity 0.1s ease-in
    &::after
      transition bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)
    .-open-navigation &
      transform rotate(225deg)
      transition-delay 0.12s
      transition-timing-function cubic-bezier(0.215, 0.61, 0.355, 1)
      &::before
        top 0
        opacity 0
        transition top 0.1s ease-out, opacity 0.1s 0.12s ease-out
      &::after
        bottom 0
        transform rotate(-90deg)
        transition bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)
