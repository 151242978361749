//body
//  lost-utility edit

:focus
  outline 0

body
  background-color $color__bg--0
  &.-open-navigation
    overflow hidden

header
.header
main:not(.-splash)
.main:not(.-splash)
footer
.footer
  margin 0 auto
  max-width 1600px
  width 100%

main
.main
  opacity 1
  transition opacity .3s
  .-open-navigation &
  &.-fadeOut
    opacity 0
    transition opacity .3s
  &:not(.-splash)
    +above(rupture.mobile-cutoff)
      padding 0 $padding-desktop
    +mobile()
      padding 70px $padding-mobile 0

main
.main
img
iframe
  display block


elementSpacing--bottom()
  margin-bottom $spacing-block
  +mobile()
    margin-bottom $spacing-block--mobile
elementSpacing--top()
  margin-top $spacing-block
  +mobile()
    margin-top $spacing-block--mobile

elementSpacing()
  elementSpacing--bottom()
  elementSpacing--top()



.-mobile
  +above(rupture.mobile-cutoff)
    display none
.-desktop
  +mobile()
    display none
