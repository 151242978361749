.intro
  +desktop()
    lost-offset 3/12
  .-page-werkverzeichnis &
    margin-bottom 150px
    margin-top 50px
    +above(rupture.mobile-cutoff)
      display none

  &__headline
    header1()
    hyphens auto
    span
      display inline-block

  &__subheadline
    responsive-typography(18,25,36,50)
    .-page-portfolio &
      +mobile()
        display none
    +above(rupture.mobile-cutoff)
      font-text-light()
      margin-bottom 65px
    .-page-team &
      +mobile()
        border-bottom 1px solid $color__text
        padding-bottom 16px
        margin-bottom 16px

  &__text
    font-text-light()
    letter-spacing .01em
    richtext()
