.navigation
  +mobile()
    display none
  &__list
    display flex
  &__link
    font-text-regular()
    letter-spacing .02em
    typography(26,26)
    +tablet()
      padding 0 2vw
    +desktop()
      padding 0 40px
  &__text
    display inline-block
    padding-bottom 6px
    .-active &
      border-bottom 6px solid $color__text
