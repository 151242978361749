@font-face
  font-family 'Soleil-Light'
  font-path '/assets/dist/fonts/Soleil-Light'
  font-weight 400
  font-style normal

@font-face
  font-family 'Soleil-Regular'
  font-path '/assets/dist/fonts/Soleil-Regular'
  font-weight 400
  font-style normal

@font-face
  font-family 'Soleil-Semibold'
  font-path '/assets/dist/fonts/Soleil-Semibold'
  font-weight 400
  font-style normal

@font-face
  font-family 'Soleil-Bold'
  font-path '/assets/dist/fonts/Soleil-Bold'
  font-weight 400
  font-style normal


font-text-light()
  font-family 'Soleil-Light', sans-serif

font-text-regular()
  font-family 'Soleil-Regular', sans-serif

font-text-semibold()
  font-family 'Soleil-Semibold', sans-serif

font-text-bold()
  font-family 'Soleil-Bold', sans-serif
