.team
  margin-top 110px
  &__content
    +tablet()
      lost-column 1/1
    +desktop()
      lost-column 8/12 1
      lost-offset 3/12

  &__list
    +mobile()
      display flex
      flex-direction column
    +above(rupture.mobile-cutoff)
      lost-flex-container row

  &__headline
    header1()
    +mobile()
      display none

  &__item
    +mobile()
      &:not(:last-child)
        margin-bottom 45px
    +tablet()
      lost-waffle 1/3 3 30px
      margin-bottom 30px
    +desktop()
      lost-column 1/4 4 50px
      &:nth-child(1n+5)
        margin-top 50px

  &__image
    +mobile()
      margin-top 100px
    +above(rupture.mobile-cutoff)
      margin-top 50px
    +tablet()
      lost-column 8/12 1
      lost-offset 4/12
    +desktop()
      lost-column 7/12 1
      lost-offset 5/12
    img
      width 100%
      height auto
