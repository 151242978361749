.block
  & + &
    margin-top 55px
  &__text
    richtext()
    +tablet()
      lost-offset 3/12
      lost-column 8/12
    +desktop()
      lost-offset 3/12
      lost-column 7/12

  // Ein Bild, Textbreite
  &__gallery0
    +tablet()
      lost-offset 3/12
      lost-column 8/12
    +desktop()
      lost-offset 3/12
      lost-column 7/12

  // Zwei Bilder, komplette Breite
  &__gallery1
    lost-flex-container row
    &column
      lost-column 1/2

  // Ein Bild nach rechts eingerückt
  &__gallery2
    +above(rupture.mobile-cutoff)
      lost-flex-container row
      &column
        lost-offset 3/12
        lost-column 9/12

  // Zwei Bilder nach rechts eingerückt
  &__gallery3
    lost-flex-container row
    &column
      lost-column 1/2
    +above(rupture.mobile-cutoff)
      lost-offset 3/12
