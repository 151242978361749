.tags
  cursor pointer
  display flex
  &.-navigation
    flex-direction column
    margin 5px 0 0
    font-text-light()
    typography(22,30)
    letter-spacing .03em
  &.-intro
    font-text-regular()
    margin-bottom 55px
    +mobile()
      letter-spacing .01em
      typography(18,25)
      flex-direction column
    +above(rupture.mobile-cutoff)
      letter-spacing .02em
      typography(14,20)
  &__item
    ^[0].-navigation &
      &:not(:first-child)
        margin-top 12px
    ^[0].-intro &
      text-transform uppercase
      &:not(:first-child)
        +mobile()
          margin-top 12px
        +above(rupture.mobile-cutoff)
          margin-left 28px
  &__button
    &.-active
      font-text-bold()

