html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
@font-face {
  font-family: 'Soleil-Light';
  src: url("/assets/dist/fonts/Soleil-Light.eot") format('embedded-opentype'),
       url("/assets/dist/fonts/Soleil-Light.woff2") format('woff2'),
       url("/assets/dist/fonts/Soleil-Light.woff") format('woff'),
       url("/assets/dist/fonts/Soleil-Light.ttf") format('truetype'),
       url("/assets/dist/fonts/Soleil-Light.svg") format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Soleil-Regular';
  src: url("/assets/dist/fonts/Soleil-Regular.eot") format('embedded-opentype'),
       url("/assets/dist/fonts/Soleil-Regular.woff2") format('woff2'),
       url("/assets/dist/fonts/Soleil-Regular.woff") format('woff'),
       url("/assets/dist/fonts/Soleil-Regular.ttf") format('truetype'),
       url("/assets/dist/fonts/Soleil-Regular.svg") format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Soleil-Semibold';
  src: url("/assets/dist/fonts/Soleil-Semibold.eot") format('embedded-opentype'),
       url("/assets/dist/fonts/Soleil-Semibold.woff2") format('woff2'),
       url("/assets/dist/fonts/Soleil-Semibold.woff") format('woff'),
       url("/assets/dist/fonts/Soleil-Semibold.ttf") format('truetype'),
       url("/assets/dist/fonts/Soleil-Semibold.svg") format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Soleil-Bold';
  src: url("/assets/dist/fonts/Soleil-Bold.eot") format('embedded-opentype'),
       url("/assets/dist/fonts/Soleil-Bold.woff2") format('woff2'),
       url("/assets/dist/fonts/Soleil-Bold.woff") format('woff'),
       url("/assets/dist/fonts/Soleil-Bold.ttf") format('truetype'),
       url("/assets/dist/fonts/Soleil-Bold.svg") format('svg');
  font-weight: 400;
  font-style: normal;
}
:focus {
  outline: 0;
}
body {
  background-color: #efeeec;
}
body.-open-navigation {
  overflow: hidden;
}
header,
.header,
main:not(.-splash),
.main:not(.-splash),
footer,
.footer {
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
}
main,
.main {
  opacity: 1;
  transition: opacity 0.3s;
}
.-open-navigation main,
.-open-navigation .main,
main.-fadeOut,
.main.-fadeOut {
  opacity: 0;
  transition: opacity 0.3s;
}
@media only screen and (min-width: 768px) {
  main:not(.-splash),
  .main:not(.-splash) {
    padding: 0 24px;
  }
}
@media only screen and (max-width: 767px) {
  main:not(.-splash),
  .main:not(.-splash) {
    padding: 70px 20px 0;
  }
}
main,
.main,
img,
iframe {
  display: block;
}
@media only screen and (min-width: 768px) {
  .-mobile {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .-desktop {
    display: none;
  }
}
body {
  font-family: 'Soleil-Regular', sans-serif;
  color: #000;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
i,
em {
  font-style: italic;
}
b,
strong {
  font-family: 'Soleil-Bold', sans-serif;
}
a {
  color: inherit;
  text-decoration: none;
}
.default__content h1 {
  font-size: calc(26px + 7 * ((100vw - 768px) / 256));
  line-height: calc(30px + 5 * ((100vw - 768px) / 256));
}
@media screen and (min-width: 1024px) {
  .default__content h1 {
    line-height: 35px;
  }
}
@media screen and (max-width: 768px) {
  .default__content h1 {
    line-height: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .default__content h1 {
    font-size: 33px;
  }
}
@media screen and (max-width: 768px) {
  .default__content h1 {
    font-size: 26px;
  }
}
.default__content h2 {
  font-size: calc(20px + 4 * ((100vw - 768px) / 256));
  line-height: calc(28px + 7 * ((100vw - 768px) / 256));
}
@media screen and (min-width: 1024px) {
  .default__content h2 {
    line-height: 35px;
  }
}
@media screen and (max-width: 768px) {
  .default__content h2 {
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  .default__content h2 {
    font-size: 24px;
  }
}
@media screen and (max-width: 768px) {
  .default__content h2 {
    font-size: 20px;
  }
}
.default__content h3 {
  font-size: calc(20px + 0 * ((100vw - 768px) / 256));
  line-height: calc(28px + 2 * ((100vw - 768px) / 256));
}
@media screen and (min-width: 1024px) {
  .default__content h3 {
    line-height: 30px;
  }
}
@media screen and (max-width: 768px) {
  .default__content h3 {
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  .default__content h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px) {
  .default__content h3 {
    font-size: 20px;
  }
}
.subcopy {
  font-size: calc(20px + 10 * ((100vw - 768px) / 256));
  line-height: calc(30px + 15 * ((100vw - 768px) / 256));
}
@media screen and (min-width: 1024px) {
  .subcopy {
    line-height: 45px;
  }
}
@media screen and (max-width: 768px) {
  .subcopy {
    line-height: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .subcopy {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .subcopy {
    font-size: 20px;
  }
}
.text--highlight {
  background-image: linear-gradient(#0ff, #0ff);
  background-position: 0 80%;
  background-repeat: no-repeat;
  background-size: 100% 6px;
}
.headline__blue,
.headline__m {
  background: #aaa;
  color: #fff;
  display: inline-block;
  font-family: 'Soleil-Bold', sans-serif;
  padding: 14px 12px 12px 12px;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 1em;
}
@media print {
  body {
    margin-top: 2cm;
    margin-right: 2cm;
    margin-bottom: 1.5cm;
    margin-left: 2cm;
  }
  * {
    color: #000 !important;
    text-align: left !important;
  }
  div,
  span,
  p,
  a,
  li {
    font-size: 100% !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em !important;
    margin-top: 0.25em !important;
    text-align: left !important;
  }
  h1 *,
  h2 *,
  h3 *,
  h4 *,
  h5 *,
  h6 * {
    margin: 0 !important;
    padding: 0 !important;
  }
  h1,
  h1 * {
    font-size: 250% !important;
    line-height: 1.3em;
  }
  h2,
  h2 * {
    font-size: 175% !important;
    line-height: 1.3em;
  }
  h3,
  h3 * {
    font-size: 135% !important;
    line-height: 1.3em;
  }
  h4,
  h4 * {
    font-size: 100% !important;
    line-height: 1.3em;
  }
  h5,
  h5 * {
    font-size: 100% !important;
    line-height: 1.3em;
  }
  h6,
  h6 * {
    font-size: 90% !important;
    line-height: 1.3em;
  }
  .block {
    margin-top: 40px;
  }
  img,
  form {
    display: none !important;
  }
}
/*
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
@media only screen and (min-width: 768px) {
  .hamburger {
    display: none;
  }
}
.hamburger:hover {
  opacity: 1;
}
.-open-navigation .hamburger .hamburger-inner,
.-open-navigation .hamburger .hamburger-inner::before,
.-open-navigation .hamburger .hamburger-inner::after {
  background-color: #000;
}
.hamburger-box {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 26px;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: #000;
  border-radius: 2px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  width: 30px;
  height: 2px;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -12px;
}
.hamburger-inner::after {
  bottom: -12px;
}
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.-open-navigation .hamburger--spin .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.-open-navigation .hamburger--spin .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.-open-navigation .hamburger--spin .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.yay {
  background: #000;
  color: #0ff;
  font-family: 'Soleil-Bold', sans-serif;
  letter-spacing: 0.1;
  padding: 0 0.25em;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
}
.logo {
  display: block;
}
.logo__image {
  height: auto;
}
@media only screen and (max-width: 767px) {
  .logo__image {
    width: 52px;
  }
}
@media only screen and (min-width: 768px) {
  .logo__image {
    width: 82px;
  }
}
@media only screen and (max-width: 767px) {
  .member {
    display: flex;
    flex-flow: row wrap;
  }
}
@media only screen and (max-width: 767px) {
  .member__image {
    align-self: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (40px - 40px * 1/2));
    max-width: calc(99.9% * 1/2 - (40px - 40px * 1/2));
    width: calc(99.9% * 1/2 - (40px - 40px * 1/2));
    margin-bottom: 20px;
  }
  .member__image:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .member__image:last-child {
    margin-right: 0;
  }
  .member__image:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media only screen and (min-width: 768px) {
  .member__image {
    width: 100%;
    margin-bottom: 30px;
  }
}
.member__name {
  font-family: 'Soleil-Semibold', sans-serif;
  letter-spacing: 0.01em;
  margin-bottom: 23px;
}
@media only screen and (max-width: 767px) {
  .member__name {
    font-size: 30px;
    line-height: 33px;
  }
}
@media only screen and (min-width: 768px) {
  .member__name {
    font-size: 18px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .member__name {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (40px - 40px * 1/2));
    max-width: calc(99.9% * 1/2 - (40px - 40px * 1/2));
    width: calc(99.9% * 1/2 - (40px - 40px * 1/2));
  }
  .member__name:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .member__name:last-child {
    margin-right: 0;
  }
  .member__name:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
  }
}
.member__birthdate {
  display: block;
}
@media only screen and (max-width: 767px) {
  .member__birthdate {
    font-family: 'Soleil-Light', sans-serif;
    font-size: 18px;
    line-height: 25px;
  }
}
.member__text {
  font-family: 'Soleil-Light', sans-serif;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  letter-spacing: 0.01em;
}
@media only screen and (max-width: 1023px) {
  .member__text {
    font-size: 18px;
    line-height: 25px;
  }
}
@media only screen and (min-width: 1024px) {
  .member__text {
    font-size: 14px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .member__text {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/1 - (40px - 40px * 1/1));
    max-width: calc(99.9% * 1/1 - (40px - 40px * 1/1));
    width: calc(99.9% * 1/1 - (40px - 40px * 1/1));
  }
  .member__text:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .member__text:last-child {
    margin-right: 0;
  }
  .member__text:nth-child(1n) {
    margin-right: 0;
    margin-left: auto;
  }
}
.navigation-mobile {
  background-color: #efeeec;
  position: fixed;
  top: 0;
  left: -100vw;
  height: 100vh;
  overflow-y: scroll;
  padding: 0 20px;
  width: 100vw;
  z-index: 99;
  transition: left 0.3s;
}
.-open-navigation .navigation-mobile {
  left: 0;
  transition: left 0.3s;
}
@media only screen and (min-width: 768px) {
  .navigation-mobile {
    display: none;
  }
}
.navigation-mobile__list {
  margin: 10vh 0;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.navigation-mobile__item:not(:first-child) {
  margin-top: 24px;
}
.navigation-mobile__link {
  font-family: 'Soleil-Light', sans-serif;
  letter-spacing: 0.02em;
  font-size: 33px;
  line-height: 33px;
}
.navigation-mobile__text {
  display: inline-block;
  padding-bottom: 6px;
}
.-active .navigation-mobile__text {
  font-family: 'Soleil-Bold', sans-serif;
}
@media only screen and (max-width: 767px) {
  .navigation {
    display: none;
  }
}
.navigation__list {
  display: flex;
}
.navigation__link {
  font-family: 'Soleil-Regular', sans-serif;
  letter-spacing: 0.02em;
  font-size: 26px;
  line-height: 26px;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .navigation__link {
    padding: 0 2vw;
  }
}
@media only screen and (min-width: 1024px) {
  .navigation__link {
    padding: 0 40px;
  }
}
.navigation__text {
  display: inline-block;
  padding-bottom: 6px;
}
.-active .navigation__text {
  border-bottom: 6px solid #000;
}
.searchbox {
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .searchbox {
    display: none;
  }
}
.searchbox__form {
  position: absolute;
  right: 20px;
  top: 80px;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s;
  width: 0;
}
.-open-searchbox .searchbox__form {
  width: 40%;
  opacity: 1;
  height: 48px;
  transition: all 0.3s;
}
.searchbox__input {
  background-color: #d1d1d0;
  border: 0 none;
  padding: 0 10px;
  font-size: 33px;
  line-height: 48px;
  width: 100%;
}
.searchbox__button {
  position: absolute;
  right: 0;
}
.tags {
  cursor: pointer;
  display: flex;
}
.tags.-navigation {
  flex-direction: column;
  margin: 5px 0 0;
  font-family: 'Soleil-Light', sans-serif;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.03em;
}
.tags.-intro {
  font-family: 'Soleil-Regular', sans-serif;
  margin-bottom: 55px;
}
@media only screen and (max-width: 767px) {
  .tags.-intro {
    letter-spacing: 0.01em;
    font-size: 18px;
    line-height: 25px;
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) {
  .tags.-intro {
    letter-spacing: 0.02em;
    font-size: 14px;
    line-height: 20px;
  }
}
.tags.-navigation .tags__item:not(:first-child) {
  margin-top: 12px;
}
.tags.-intro .tags__item {
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .tags.-intro .tags__item:not(:first-child) {
    margin-top: 12px;
  }
}
@media only screen and (min-width: 768px) {
  .tags.-intro .tags__item:not(:first-child) {
    margin-left: 28px;
  }
}
.tags__button.-active {
  font-family: 'Soleil-Bold', sans-serif;
}
.team {
  margin-top: 110px;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .team__content {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/1 - (40px - 40px * 1/1));
    max-width: calc(99.9% * 1/1 - (40px - 40px * 1/1));
    width: calc(99.9% * 1/1 - (40px - 40px * 1/1));
  }
  .team__content:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .team__content:last-child {
    margin-right: 0;
  }
  .team__content:nth-child(1n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .team__content {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    margin-left: calc(99.9% * (-3/12 * -1) - (40px - 40px * (-3/12 * -1)) + 40px) !important;
  }
  .team__content:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .team__content:last-child {
    margin-right: 0;
  }
  .team__content:nth-child(1n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media only screen and (max-width: 767px) {
  .team__list {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) {
  .team__list {
    display: flex;
    flex-flow: row wrap;
  }
}
.team__headline {
  font-family: 'Soleil-Bold', sans-serif;
  letter-spacing: 0.01em;
  font-size: calc(45px + 75 * ((100vw - 768px) / 256));
  line-height: calc(50px + 94 * ((100vw - 768px) / 256));
}
@media screen and (min-width: 1024px) {
  .team__headline {
    line-height: 144px;
  }
}
@media screen and (max-width: 768px) {
  .team__headline {
    line-height: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .team__headline {
    font-size: 120px;
  }
}
@media screen and (max-width: 768px) {
  .team__headline {
    font-size: 45px;
  }
}
@media only screen and (max-width: 767px) {
  .team__headline {
    margin-bottom: 12px;
  }
}
@media only screen and (min-width: 768px) {
  .team__headline {
    margin-bottom: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .team__headline {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .team__item:not(:last-child) {
    margin-bottom: 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .team__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/3 - (30px - 30px * 1/3));
    width: calc(99.9% * 1/3 - (30px - 30px * 1/3));
    max-width: calc(99.9% * 1/3 - (30px - 30px * 1/3));
    height: calc(99.9% * 1/3 - (30px - 30px * 1/3));
    margin-bottom: 30px;
  }
  .team__item:nth-child(1n) {
    margin-right: 30px;
    margin-bottom: 30px;
    margin-left: 0;
  }
  .team__item:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  .team__item:nth-child(3n) {
    margin-right: 0;
    margin-left: auto;
  }
  .team__item:nth-last-child(-n + 3) {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .team__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/4 - (50px - 50px * 1/4));
    max-width: calc(99.9% * 1/4 - (50px - 50px * 1/4));
    width: calc(99.9% * 1/4 - (50px - 50px * 1/4));
  }
  .team__item:nth-child(1n) {
    margin-right: 50px;
    margin-left: 0;
  }
  .team__item:last-child {
    margin-right: 0;
  }
  .team__item:nth-child(4n) {
    margin-right: 0;
    margin-left: auto;
  }
  .team__item:nth-child(1n+5) {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .team__image {
    margin-top: 100px;
  }
}
@media only screen and (min-width: 768px) {
  .team__image {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .team__image {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    margin-left: calc(99.9% * (-4/12 * -1) - (40px - 40px * (-4/12 * -1)) + 40px) !important;
  }
  .team__image:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .team__image:last-child {
    margin-right: 0;
  }
  .team__image:nth-child(1n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .team__image {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
    max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
    width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
    margin-left: calc(99.9% * (-5/12 * -1) - (40px - 40px * (-5/12 * -1)) + 40px) !important;
  }
  .team__image:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .team__image:last-child {
    margin-right: 0;
  }
  .team__image:nth-child(1n) {
    margin-right: 0;
    margin-left: auto;
  }
}
.team__image img {
  width: 100%;
  height: auto;
}
[data-tippy-root],
.tippy-box,
.tippy-content {
  padding: 0;
  border: 0 none;
}
.tippy-content {
  text-transform: uppercase;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.tippy-arrow,
.tippy-arrow:before {
  display: none;
}
.tippy-box {
  background-color: transparent;
  color: #000;
}
.tippy-box:before {
  background-image: url("/assets/dist/images/icon__arrow-tooltip.svg");
  background-repeat: no-repeat;
  content: '';
  display: block;
  height: 30px;
  left: -40px;
  position: absolute;
  top: 2px;
  width: 40px;
}
.block + .block {
  margin-top: 55px;
}
.block__text a:not([class*=a-button]) {
  text-decoration: underline;
}
.block__text p,
.block__text ul,
.block__text ol {
  font-size: calc(18px + 0 * ((100vw - 768px) / 256));
  line-height: calc(25px + 0 * ((100vw - 768px) / 256));
}
@media screen and (min-width: 1024px) {
  .block__text p,
.block__text ul,
.block__text ol {
    line-height: 25px;
  }
}
@media screen and (max-width: 768px) {
  .block__text p,
.block__text ul,
.block__text ol {
    line-height: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .block__text p,
.block__text ul,
.block__text ol {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .block__text p,
.block__text ul,
.block__text ol {
    font-size: 18px;
  }
}
.block__text p + p,
.block__text ul + p,
.block__text ol + p,
.block__text p + ul,
.block__text ul + ul,
.block__text ol + ul,
.block__text p + ol,
.block__text ul + ol,
.block__text ol + ol {
  margin-top: 1.25em;
}
.block__text p + h2,
.block__text ul + h2,
.block__text ol + h2,
.block__text p + h3,
.block__text ul + h3,
.block__text ol + h3 {
  margin-top: 1.5em;
}
.block__text h2 + h2,
.block__text h3 + h2,
.block__text h2 + h3,
.block__text h3 + h3 {
  margin-top: 1em;
}
.block__text li + li {
  margin-top: 0.5em;
}
.block__text ul li {
  padding-left: 1.25em;
}
.block__text ol {
  margin-left: 1.25em;
  list-style-type: numeric;
}
.block__text ul li {
  position: relative;
}
.block__text ul li:before {
  content: '';
  display: block;
  width: 0.5em;
  height: 0.5em;
  position: absolute;
  left: 0;
  top: 0.5em;
  background-color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .block__text {
    margin-left: calc(99.9% * (-3/12 * -1) - (40px - 40px * (-3/12 * -1)) + 40px) !important;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
  }
  .block__text:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .block__text:last-child {
    margin-right: 0;
  }
  .block__text:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .block__text {
    margin-left: calc(99.9% * (-3/12 * -1) - (40px - 40px * (-3/12 * -1)) + 40px) !important;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
    max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
    width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
  }
  .block__text:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .block__text:last-child {
    margin-right: 0;
  }
  .block__text:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .block__gallery0 {
    margin-left: calc(99.9% * (-3/12 * -1) - (40px - 40px * (-3/12 * -1)) + 40px) !important;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
  }
  .block__gallery0:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .block__gallery0:last-child {
    margin-right: 0;
  }
  .block__gallery0:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .block__gallery0 {
    margin-left: calc(99.9% * (-3/12 * -1) - (40px - 40px * (-3/12 * -1)) + 40px) !important;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
    max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
    width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
  }
  .block__gallery0:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .block__gallery0:last-child {
    margin-right: 0;
  }
  .block__gallery0:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
.block__gallery1 {
  display: flex;
  flex-flow: row wrap;
}
.block__gallery1column {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 1/2 - (40px - 40px * 1/2));
  max-width: calc(99.9% * 1/2 - (40px - 40px * 1/2));
  width: calc(99.9% * 1/2 - (40px - 40px * 1/2));
}
.block__gallery1column:nth-child(1n) {
  margin-right: 40px;
  margin-left: 0;
}
.block__gallery1column:last-child {
  margin-right: 0;
}
.block__gallery1column:nth-child(2n) {
  margin-right: 0;
  margin-left: auto;
}
@media only screen and (min-width: 768px) {
  .block__gallery2 {
    display: flex;
    flex-flow: row wrap;
  }
  .block__gallery2column {
    margin-left: calc(99.9% * (-3/12 * -1) - (40px - 40px * (-3/12 * -1)) + 40px) !important;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 9/12 - (40px - 40px * 9/12));
    max-width: calc(99.9% * 9/12 - (40px - 40px * 9/12));
    width: calc(99.9% * 9/12 - (40px - 40px * 9/12));
  }
  .block__gallery2column:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .block__gallery2column:last-child {
    margin-right: 0;
  }
  .block__gallery2column:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
.block__gallery3 {
  display: flex;
  flex-flow: row wrap;
}
.block__gallery3column {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 1/2 - (40px - 40px * 1/2));
  max-width: calc(99.9% * 1/2 - (40px - 40px * 1/2));
  width: calc(99.9% * 1/2 - (40px - 40px * 1/2));
}
.block__gallery3column:nth-child(1n) {
  margin-right: 40px;
  margin-left: 0;
}
.block__gallery3column:last-child {
  margin-right: 0;
}
.block__gallery3column:nth-child(2n) {
  margin-right: 0;
  margin-left: auto;
}
@media only screen and (min-width: 768px) {
  .block__gallery3 {
    margin-left: calc(99.9% * (-3/12 * -1) - (40px - 40px * (-3/12 * -1)) + 40px) !important;
  }
}
.default {
  display: flex;
  flex-flow: row wrap;
}
.default__content a:not([class*=a-button]) {
  text-decoration: underline;
}
.default__content p,
.default__content ul,
.default__content ol {
  font-size: calc(18px + 0 * ((100vw - 768px) / 256));
  line-height: calc(25px + 0 * ((100vw - 768px) / 256));
}
@media screen and (min-width: 1024px) {
  .default__content p,
.default__content ul,
.default__content ol {
    line-height: 25px;
  }
}
@media screen and (max-width: 768px) {
  .default__content p,
.default__content ul,
.default__content ol {
    line-height: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .default__content p,
.default__content ul,
.default__content ol {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .default__content p,
.default__content ul,
.default__content ol {
    font-size: 18px;
  }
}
.default__content p + p,
.default__content ul + p,
.default__content ol + p,
.default__content p + ul,
.default__content ul + ul,
.default__content ol + ul,
.default__content p + ol,
.default__content ul + ol,
.default__content ol + ol {
  margin-top: 1.25em;
}
.default__content p + h2,
.default__content ul + h2,
.default__content ol + h2,
.default__content p + h3,
.default__content ul + h3,
.default__content ol + h3 {
  margin-top: 1.5em;
}
.default__content h2 + h2,
.default__content h3 + h2,
.default__content h2 + h3,
.default__content h3 + h3 {
  margin-top: 1em;
}
.default__content li + li {
  margin-top: 0.5em;
}
.default__content ul li {
  padding-left: 1.25em;
}
.default__content ol {
  margin-left: 1.25em;
  list-style-type: numeric;
}
.default__content ul li {
  position: relative;
}
.default__content ul li:before {
  content: '';
  display: block;
  width: 0.5em;
  height: 0.5em;
  position: absolute;
  left: 0;
  top: 0.5em;
  background-color: #000;
}
@media only screen and (max-width: 767px) {
  .default__content {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/1 - (40px - 40px * 1/1));
    max-width: calc(99.9% * 1/1 - (40px - 40px * 1/1));
    width: calc(99.9% * 1/1 - (40px - 40px * 1/1));
  }
  .default__content:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .default__content:last-child {
    margin-right: 0;
  }
  .default__content:nth-child(1n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .default__content {
    margin-left: calc(99.9% * (-2/12 * -1) - (40px - 40px * (-2/12 * -1)) + 40px) !important;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
  }
  .default__content:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .default__content:last-child {
    margin-right: 0;
  }
  .default__content:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .default__content {
    margin-left: calc(99.9% * (-3/12 * -1) - (40px - 40px * (-3/12 * -1)) + 40px) !important;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
    width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
  }
  .default__content:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .default__content:last-child {
    margin-right: 0;
  }
  .default__content:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .directory {
    margin-left: calc(99.9% * (-3/12 * -1) - (40px - 40px * (-3/12 * -1)) + 40px) !important;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 9/12 - (40px - 40px * 9/12));
    max-width: calc(99.9% * 9/12 - (40px - 40px * 9/12));
    width: calc(99.9% * 9/12 - (40px - 40px * 9/12));
  }
  .directory:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .directory:last-child {
    margin-right: 0;
  }
  .directory:nth-child(1n) {
    margin-right: 0;
    margin-left: auto;
  }
}
.directory__table {
  width: 100%;
}
.directory__item {
  list-style-type: none;
  font-size: 14px;
  line-height: 20px;
}
@media only screen and (max-width: 767px) {
  .directory__item {
    border-bottom: 1px solid #000;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .directory__item {
    display: grid;
    gap: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .directory__item {
    grid-template-columns: 2fr 4fr 3fr 2fr 2fr 1fr;
  }
}
@media only screen and (min-width: 1024px) {
  .directory__item {
    grid-template-columns: 1fr 4fr 1fr 1fr 1fr 1fr;
  }
}
.directory__cell {
  font-family: 'Soleil-Light', sans-serif;
}
@media only screen and (max-width: 767px) {
  .directory__cell {
    padding: 5px 0;
  }
}
@media only screen and (min-width: 768px) {
  .directory__cell {
    align-items: flex-end;
    border-bottom: 1px solid #000;
    display: flex;
    min-height: 76px;
    padding: 10px 0;
  }
}
.directory__header {
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
@media only screen and (max-width: 767px) {
  .directory__header {
    display: none;
  }
}
.directory__nosort {
  pointer-events: none;
}
.directory__sort:hover {
  cursor: pointer;
}
.directory__sort span {
  padding-right: 15px;
  position: relative;
}
.directory__sort span:after {
  position: absolute;
  right: 0;
  top: 3px;
  border-color: transparent transparent #000 transparent;
  border-width: 0 4px 6px 4px;
}
.directory__sort span:before {
  position: absolute;
  right: 0;
  bottom: 3px;
  border-color: #000 transparent transparent transparent;
  border-width: 6px 4px 0 4px;
}
.directory__sort span:after,
.directory__sort span:before {
  border-style: solid;
  content: '';
  height: 0;
  line-height: 0;
  transition: border-color 0.3s;
  width: 0;
}
.directory__sort.dir-d span:after {
  border-bottom-color: #aaa;
  transition: border-color 0.3s;
}
.directory__sort.dir-u span:before {
  border-top-color: #aaa;
  transition: border-color 0.3s;
}
.directory__link {
  background-image: url("/assets/dist/images/icon__arrow-directory.svg");
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 20px;
}
@media only screen and (min-width: 768px) {
  .directory__link {
    display: block;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .directory__status {
    order: 2;
    width: calc(50% - 10px);
    margin-right: 20px;
    font-family: 'Soleil-Bold', sans-serif;
  }
}
@media only screen and (max-width: 767px) {
  .directory__title {
    font-family: 'Soleil-Bold', sans-serif;
    order: 1;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .directory__years {
    font-family: 'Soleil-Bold', sans-serif;
    order: 4;
    width: calc(50% - 10px);
    margin-right: 20px;
  }
  .directory__years:before {
    content: 'Zeitraum:';
  }
}
@media only screen and (max-width: 767px) {
  .directory__area {
    order: 3;
    width: calc(50% - 10px);
  }
  .directory__area:before {
    content: 'Fläche:';
  }
}
@media only screen and (max-width: 767px) {
  .directory__yay {
    order: 5;
    width: calc(50% - 10px);
  }
}
@media only screen and (min-width: 768px) {
  .directory__yay {
    justify-content: space-around;
  }
}
.footer {
  display: flex;
  font-family: 'Soleil-Regular', sans-serif;
  justify-content: space-between;
  padding: 140px 24px 40px 24px;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}
.footer__item:nth-child(1n+2) {
  margin-left: 43px;
}
@media only screen and (max-width: 767px) {
  .footer__item span {
    display: block;
  }
}
.footer__copyright {
  text-align: right;
}
.fullstage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-around;
}
.fullstage__image {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.fullstage__logo {
  align-self: center;
  height: auto;
  position: relative;
  max-width: 620px;
}
@media only screen and (min-width: 768px) {
  .fullstage__logo {
    transform: translate(0, -25%);
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .fullstage__logo {
    position: absolute;
    top: 130px;
    width: 70%;
  }
}
.fullstage__arrowScroll {
  position: absolute;
  bottom: 60px;
  align-self: center;
  height: auto;
  width: 35px;
}
.gallery__image {
  height: auto;
  max-width: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .header {
    background-color: #efeeec;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    height: 70px;
  }
}
.header__hamburger {
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 100;
}
@media only screen and (max-width: 767px) {
  .header {
    padding: 15px 20px 0 20px;
  }
}
@media only screen and (min-width: 768px) {
  .header {
    padding: 30px 24px 160px 24px;
  }
}
@media only screen and (min-width: 1024px) {
  .intro {
    margin-left: calc(99.9% * (-3/12 * -1) - (40px - 40px * (-3/12 * -1)) + 40px) !important;
  }
}
.-page-werkverzeichnis .intro {
  margin-bottom: 150px;
  margin-top: 50px;
}
@media only screen and (min-width: 768px) {
  .-page-werkverzeichnis .intro {
    display: none;
  }
}
.intro__headline {
  font-family: 'Soleil-Bold', sans-serif;
  letter-spacing: 0.01em;
  font-size: calc(45px + 75 * ((100vw - 768px) / 256));
  line-height: calc(50px + 94 * ((100vw - 768px) / 256));
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
@media screen and (min-width: 1024px) {
  .intro__headline {
    line-height: 144px;
  }
}
@media screen and (max-width: 768px) {
  .intro__headline {
    line-height: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .intro__headline {
    font-size: 120px;
  }
}
@media screen and (max-width: 768px) {
  .intro__headline {
    font-size: 45px;
  }
}
@media only screen and (max-width: 767px) {
  .intro__headline {
    margin-bottom: 12px;
  }
}
@media only screen and (min-width: 768px) {
  .intro__headline {
    margin-bottom: 36px;
  }
}
.intro__headline span {
  display: inline-block;
}
.intro__subheadline {
  font-size: calc(18px + 18 * ((100vw - 768px) / 256));
  line-height: calc(25px + 25 * ((100vw - 768px) / 256));
}
@media screen and (min-width: 1024px) {
  .intro__subheadline {
    line-height: 50px;
  }
}
@media screen and (max-width: 768px) {
  .intro__subheadline {
    line-height: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .intro__subheadline {
    font-size: 36px;
  }
}
@media screen and (max-width: 768px) {
  .intro__subheadline {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .-page-portfolio .intro__subheadline {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .intro__subheadline {
    font-family: 'Soleil-Light', sans-serif;
    margin-bottom: 65px;
  }
}
@media only screen and (max-width: 767px) {
  .-page-team .intro__subheadline {
    border-bottom: 1px solid #000;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}
.intro__text {
  font-family: 'Soleil-Light', sans-serif;
  letter-spacing: 0.01em;
}
.intro__text a:not([class*=a-button]) {
  text-decoration: underline;
}
.intro__text p,
.intro__text ul,
.intro__text ol {
  font-size: calc(18px + 0 * ((100vw - 768px) / 256));
  line-height: calc(25px + 0 * ((100vw - 768px) / 256));
}
@media screen and (min-width: 1024px) {
  .intro__text p,
.intro__text ul,
.intro__text ol {
    line-height: 25px;
  }
}
@media screen and (max-width: 768px) {
  .intro__text p,
.intro__text ul,
.intro__text ol {
    line-height: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .intro__text p,
.intro__text ul,
.intro__text ol {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .intro__text p,
.intro__text ul,
.intro__text ol {
    font-size: 18px;
  }
}
.intro__text p + p,
.intro__text ul + p,
.intro__text ol + p,
.intro__text p + ul,
.intro__text ul + ul,
.intro__text ol + ul,
.intro__text p + ol,
.intro__text ul + ol,
.intro__text ol + ol {
  margin-top: 1.25em;
}
.intro__text p + h2,
.intro__text ul + h2,
.intro__text ol + h2,
.intro__text p + h3,
.intro__text ul + h3,
.intro__text ol + h3 {
  margin-top: 1.5em;
}
.intro__text h2 + h2,
.intro__text h3 + h2,
.intro__text h2 + h3,
.intro__text h3 + h3 {
  margin-top: 1em;
}
.intro__text li + li {
  margin-top: 0.5em;
}
.intro__text ul li {
  padding-left: 1.25em;
}
.intro__text ol {
  margin-left: 1.25em;
  list-style-type: numeric;
}
.intro__text ul li {
  position: relative;
}
.intro__text ul li:before {
  content: '';
  display: block;
  width: 0.5em;
  height: 0.5em;
  position: absolute;
  left: 0;
  top: 0.5em;
  background-color: #000;
}
.project {
  display: flex;
  flex-flow: row wrap;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .project__sidebar {
    position: absolute;
    left: 0;
    top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .project__sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (40px - 40px * 3/12));
    max-width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
    width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
  }
  .project__sidebar:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .project__sidebar:last-child {
    margin-right: 0;
  }
  .project__sidebar:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .project__sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/12 - (40px - 40px * 2/12));
    max-width: calc(99.9% * 2/12 - (40px - 40px * 2/12));
    width: calc(99.9% * 2/12 - (40px - 40px * 2/12));
    margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important;
  }
  .project__sidebar:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .project__sidebar:last-child {
    margin-right: 0;
  }
  .project__sidebar:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
.project__projectImage {
  height: auto;
  margin-bottom: 64px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .project__projectImage {
    display: none;
  }
}
.project__aspect {
  display: flex;
  flex-direction: column;
}
.project__aspect + .project__aspect {
  margin-top: 22px;
}
.project__sidebar-headline {
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .project__sidebar-headline {
    font-family: 'Soleil-Light', sans-serif;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.03em;
  }
}
@media only screen and (min-width: 768px) {
  .project__sidebar-headline {
    letter-spacing: 0.02em;
    font-size: 14px;
    line-height: 22px;
  }
}
.project__sidebar-text {
  font-family: 'Soleil-Bold', sans-serif;
}
@media only screen and (max-width: 767px) {
  .project__sidebar-text {
    letter-spacing: 0.01em;
    font-size: 18px;
    line-height: 23px;
  }
}
@media only screen and (min-width: 768px) {
  .project__sidebar-text {
    font-size: 14px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .project__content {
    margin-top: 60px;
  }
}
.project__footer {
  margin-top: 55px;
  width: 100%;
}
.project__footer:after {
  background-color: #000;
  content: '';
  display: block;
}
@media only screen and (max-width: 767px) {
  .project__footer:after {
    height: 4px;
    margin-top: 14px;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .project__footer:after {
    height: 6px;
    margin-top: 22px;
    width: 112px;
  }
}
@media only screen and (min-width: 768px) {
  .project__footer {
    margin-left: calc(99.9% * (-3/12 * -1) - (40px - 40px * (-3/12 * -1)) + 40px) !important;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
    max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
    width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
  }
  .project__footer:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
  }
  .project__footer:last-child {
    margin-right: 0;
  }
  .project__footer:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
.project__navigation {
  display: flex;
  justify-content: flex-end;
}
.project__navigationList {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
}
@media only screen and (max-width: 767px) {
  .project__navigationList {
    margin: 20px 0 40px;
  }
}
@media only screen and (min-width: 768px) {
  .project__navigationList {
    position: relative;
    top: -32px;
  }
}
.project__navigationItem:last-child {
  align-self: flex-end;
}
@media only screen and (max-width: 767px) {
  .project__navigationItem:last-child {
    margin-top: 12px;
  }
}
@media only screen and (min-width: 768px) {
  .project__navigationItem:last-child {
    margin-top: 6px;
  }
}
.project__navigationLink {
  align-items: center;
  display: flex;
}
@media only screen and (min-width: 768px) {
  .project__navigationLink {
    white-space: nowrap;
  }
}
.project__navigationArrow {
  width: 16px;
}
.project__navigationArrow--prev {
  transform: rotate(180deg);
  margin-right: 14px;
}
.project__navigationArrow--next {
  margin-left: 14px;
}
.projects {
  opacity: 0;
}
.projects.-fadeIn {
  opacity: 1;
  transition: opacity 0.5s;
}
@media only screen and (max-width: 767px) {
  .projects__gutter {
    width: 45px;
  }
}
@media only screen and (min-width: 768px) {
  .projects__gutter {
    width: 20px;
  }
}
.projects__item {
  float: left;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .projects__item {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .projects__item {
    cursor: none;
    width: calc((100% - 20px) / 2);
  }
}
@media only screen and (min-width: 1600px) {
  .projects__item {
    width: calc((100% - 40px) / 3);
  }
}
.projects__image {
  display: block;
  height: auto;
  width: 100%;
}
.projects__yay {
  position: absolute;
  right: 0;
  top: 0;
}
.projects__projectTitle {
  letter-spacing: 0.03em;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 23px;
}
@media only screen and (min-width: 1024px) {
  .projects__projectTitle {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .search {
    margin-top: 40px;
  }
}
.search__type {
  font-size: calc(20px + 4 * ((100vw - 768px) / 256));
  line-height: calc(28px + 7 * ((100vw - 768px) / 256));
}
@media screen and (min-width: 1024px) {
  .search__type {
    line-height: 35px;
  }
}
@media screen and (max-width: 768px) {
  .search__type {
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  .search__type {
    font-size: 24px;
  }
}
@media screen and (max-width: 768px) {
  .search__type {
    font-size: 20px;
  }
}
.search__title {
  font-size: calc(26px + 7 * ((100vw - 768px) / 256));
  line-height: calc(30px + 5 * ((100vw - 768px) / 256));
  margin-top: 0.1em;
}
@media screen and (min-width: 1024px) {
  .search__title {
    line-height: 35px;
  }
}
@media screen and (max-width: 768px) {
  .search__title {
    line-height: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .search__title {
    font-size: 33px;
  }
}
@media screen and (max-width: 768px) {
  .search__title {
    font-size: 26px;
  }
}
.search__item {
  margin-bottom: 80px;
}
.search__text {
  margin-top: 0.75em;
  font-family: 'Soleil-Light', sans-serif;
  letter-spacing: 0.01em;
}
.search__text a:not([class*=a-button]) {
  text-decoration: underline;
}
.search__text p,
.search__text ul,
.search__text ol {
  font-size: calc(18px + 0 * ((100vw - 768px) / 256));
  line-height: calc(25px + 0 * ((100vw - 768px) / 256));
}
@media screen and (min-width: 1024px) {
  .search__text p,
.search__text ul,
.search__text ol {
    line-height: 25px;
  }
}
@media screen and (max-width: 768px) {
  .search__text p,
.search__text ul,
.search__text ol {
    line-height: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .search__text p,
.search__text ul,
.search__text ol {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .search__text p,
.search__text ul,
.search__text ol {
    font-size: 18px;
  }
}
.search__text p + p,
.search__text ul + p,
.search__text ol + p,
.search__text p + ul,
.search__text ul + ul,
.search__text ol + ul,
.search__text p + ol,
.search__text ul + ol,
.search__text ol + ol {
  margin-top: 1.25em;
}
.search__text p + h2,
.search__text ul + h2,
.search__text ol + h2,
.search__text p + h3,
.search__text ul + h3,
.search__text ol + h3 {
  margin-top: 1.5em;
}
.search__text h2 + h2,
.search__text h3 + h2,
.search__text h2 + h3,
.search__text h3 + h3 {
  margin-top: 1em;
}
.search__text li + li {
  margin-top: 0.5em;
}
.search__text ul li {
  padding-left: 1.25em;
}
.search__text ol {
  margin-left: 1.25em;
  list-style-type: numeric;
}
.search__text ul li {
  position: relative;
}
.search__text ul li:before {
  content: '';
  display: block;
  width: 0.5em;
  height: 0.5em;
  position: absolute;
  left: 0;
  top: 0.5em;
  background-color: #000;
}
