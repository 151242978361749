.searchbox
  cursor pointer
  +mobile()
    display none
  &__form
    absolute right 20px top 80px
    height 0
    opacity 0
    overflow hidden
    transition all .3s
    width 0
    .-open-searchbox &
      width 40%
      opacity 1
      height 48px
      transition all .3s
  &__input
    background-color #d1d1d0
    border 0 none
    padding 0 10px
    typography(33,48)
    width 100%
  &__button
    absolute right 0
