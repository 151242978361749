.projects

  opacity 0

  &.-fadeIn
    opacity 1
    transition opacity .5s

  &__gutter
    +mobile()
      width 45px
    +above(rupture.mobile-cutoff)
      width 20px

  &__item
    float left
    relative 0
    +mobile()
      width 100%
    +above(rupture.mobile-cutoff)
      cursor none
      width calc((100% - 20px) / 2)
    +hd()
      width calc((100% - 40px) / 3)

  &__image
    display block
    height auto
    width 100%

  &__yay
    absolute right top

  &__projectTitle
    letter-spacing .03em
    margin-top 10px
    text-transform uppercase
    typography(18,23)
    +desktop()
      display none

