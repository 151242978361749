@media print
  body
    margin-top: 2cm;
    margin-right: 2cm;
    margin-bottom: 1.5cm;
    margin-left: 2cm
  *
    color #000 !important
    text-align left !important
  div
  span
  p
  a
  li
    font-size 100% !important
  h1
  h2
  h3
  h4
  h5
  h6
    margin-bottom 1em !important
    margin-top .25em !important
    text-align left !important
    *
      margin 0 !important
      padding 0 !important
  h1
  h1 *
    font-size 250% !important
    line-height 1.3em
  h2
  h2 *
    font-size 175% !important
    line-height 1.3em
  h3
  h3 *
    font-size 135% !important
    line-height 1.3em
  h4
  h4 *
    font-size 100% !important
    line-height 1.3em
  h5
  h5 *
    font-size 100% !important
    line-height 1.3em
  h6
  h6 *
    font-size 90% !important
    line-height 1.3em
  .block
    margin-top $lost-gutter
  img
  form
    display none !important