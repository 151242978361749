.navigation-mobile
  background-color $color__bg--0
  fixed top left -100vw
  height 100vh
  overflow-y scroll
  padding 0 $padding-mobile
  width 100vw
  z-index 99
  transition left .3s
  .-open-navigation &
    left 0
    transition left .3s
  +above(rupture.mobile-cutoff)
    display none

  &__list
    margin 10vh 0
    display flex
    flex-direction column
    text-align center

  &__item
    &:not(:first-child)
      margin-top 24px

  &__link
    font-text-light()
    letter-spacing .02em
    typography(33,33)

  &__text
    display inline-block
    padding-bottom 6px
    .-active &
      font-text-bold()
