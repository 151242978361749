.fullstage
  display flex
  flex-direction column
  height 100vh
  justify-content space-around
  &__image
    position absolute
    object-fit cover
    width 100%
    height 100%
  &__logo
    align-self center
    height auto
    relative 0
    max-width 620px
    +above($mobile-cutoff)
      transform translate(0,-25%)
      width 50%
    +mobile()
      absolute top 130px
      width 70%
  &__arrowScroll
    absolute bottom 60px
    align-self center
    height auto
    width 35px
