.directory

  +desktop()
    lost-offset 3/12
    lost-column 9/12 1

  &__table
    width 100%

  &__item
    list-style-type none
    typography(14,20)
    +mobile()
      border-bottom 1px solid $color__text
      display flex
      flex-direction row
      flex-wrap wrap
      padding-bottom 20px
      margin-bottom 20px
    +above(rupture.mobile-cutoff)
      display grid
      gap 20px
    +tablet()
      grid-template-columns 2fr 4fr 3fr 2fr 2fr 1fr
    +desktop()
      grid-template-columns 1fr 4fr 1fr 1fr 1fr 1fr

  &__cell
    font-text-light()
    +mobile()
      padding 5px 0
    +above(rupture.mobile-cutoff)
      align-items flex-end
      border-bottom 1px solid $color__text
      display flex
      min-height 76px
      padding 10px 0

  &__header
    letter-spacing .02em
    text-align left
    text-transform uppercase
    hyphens auto
    +mobile()
      display none

  &__nosort
    pointer-events none

  &__sort
    &:hover
      cursor pointer
    span
      padding-right 15px
      relative 0
      &:after
        absolute right top 3px
        border-color transparent transparent #0 transparent
        border-width 0 4px 6px 4px
      &:before
        absolute right bottom 3px
        border-color #0 transparent transparent transparent
        border-width 6px 4px 0 4px
      &:after
      &:before
        border-style solid
        content ''
        height 0
        line-height 0
        transition border-color .3s
        width 0
    &.dir-d
      span
        &:after
          border-bottom-color #a
          transition border-color .3s
    &.dir-u
      span
        &:before
          border-top-color #a
          transition border-color .3s

  &__link
    background-image url(/assets/dist/images/icon__arrow-directory.svg)
    background-position left center
    background-repeat no-repeat
    padding-left 20px
    +above(rupture.mobile-cutoff)
      display block
      width 100%

  &__status
    +mobile()
      order 2
      width calc(50% - 10px)
      margin-right 20px
      font-text-bold()

  &__title
    +mobile()
      font-text-bold()
      order 1
      width 100%

  &__years
    +mobile()
      font-text-bold()
      order 4
      width calc(50% - 10px)
      margin-right 20px
      &:before
        content 'Zeitraum:'

  &__area
    +mobile()
      &:before
        content 'Fläche:'
      order 3
      width calc(50% - 10px)

  &__yay
    +mobile()
      order 5
      width calc(50% - 10px)
    +above(rupture.mobile-cutoff)
      justify-content space-around
