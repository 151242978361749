.header
  display flex
  justify-content space-between
  relative 0
  +mobile()
    background-color $color__bg--0
    fixed top left
    width 100%
    z-index 100
    height 70px
  &__hamburger
    position absolute
    right $padding-mobile
    top 15px
    z-index 100
  +mobile()
    padding 15px $padding-mobile 0 $padding-mobile
  +above(rupture.mobile-cutoff)
    padding 30px $padding-desktop 160px $padding-desktop
