.search
  +mobile()
    margin-top $lost-gutter
  &__type
    header3()
  &__title
    header2()
    margin-top .1em
  &__item
    margin-bottom $lost-gutter-2
  &__text
    margin-top .75em
    font-text-light()
    letter-spacing .01em
    richtext()
