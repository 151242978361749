[data-tippy-root]
.tippy-box
.tippy-content
  padding 0
  border 0 none

.tippy-content
  text-transform uppercase
  hyphens auto

.tippy-arrow
  &
  &:before
    display none

.tippy-box
  background-color transparent
  color $color__text
  &:before
    background-image url('/assets/dist/images/icon__arrow-tooltip.svg')
    background-repeat no-repeat
    content ''
    display block
    height 30px
    left -40px
    position absolute
    top 2px
    width 40px
